<template>
  <div class="new-container">
    <div class="keywords">
      Competitors Keywords
    </div>
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader/index';

export default {
  name: 'CompetitorsKeywords',
  components: {
    'the-header': TheHeader,
  },
  data() {
    return {

    }
  },
  methods: {
  },

  mounted() {
  },
  computed: {
  },
}
</script>

<style lang="scss" src="./styles.scss" scoped></style>